import useFetch from "use-http";
import { mapCountryName } from "./util";

const goodCityData = [
  "the-world",
  "afghanistan",
  "albania",
  "algeria",
  "argentina",
  "armenia",
  "australia",
  "austria",
  "azerbaijan",
  "bangladesh",
  "belarus",
  "belgium",
  "bolivia",
  "bosnia-and-herzegovina",
  "botswana",
  "brazil",
  "bulgaria",
  "cameroon",
  "canada",
  "chile",
  "china",
  "colombia",
  "costa-rica",
  "croatia",
  "cuba",
  "czech-republic",
  "denmark",
  "dominican-republic",
  "ecuador",
  "egypt",
  "el-salvador",
  "estonia",
  "ethiopia",
  "finland",
  "france",
  "georgia",
  "germany",
  "greece",
  "guatemala",
  "honduras",
  "hungary",
  "india",
  "indonesia",
  "iran",
  "iraq",
  "ireland",
  "israel",
  "italy",
  "jamaica",
  "japan",
  "jordan",
  "kazakhstan",
  "kenya",
  "kuwait",
  "kyrgyzstan",
  "latvia",
  "lebanon",
  "libya",
  "lithuania",
  "macedonia",
  "malawi",
  "malaysia",
  "mexico",
  "moldova",
  "montenegro",
  "morocco",
  "myanmar",
  "namibia",
  "nepal",
  "netherlands",
  "oman",
  "pakistan",
  "palestine",
  "panama",
  "paraguay",
  "peru",
  "philippines",
  "poland",
  "portugal",
  "korea-south",
  "kosovo",
  "romania",
  "russia",
  "saudi-arabia",
  "senegal",
  "serbia",
  "slovakia",
  "slovenia",
  "south-africa",
  "spain",
  "sri-lanka",
  "sudan",
  "swaziland",
  "sweden",
  "switzerland",
  "thailand",
  "tunisia",
  "turkey",
  "uganda",
  "ukraine",
  "united-arab-emirates",
  "united-kingdom",
  "united-states",
  "uruguay",
  "vietnam",
  "yemen",
  "zambia",
  "zimbabwe",
];

export function useCovidData() {
  const { loading, error, data } = useFetch(
    "https://api.covid19api.com/summary",
    {},
    []
  );

  let countryData;
  if (!loading && !error) {
    countryData = [
      {
        key: "world",
        text: "The World",
        value: "the-world",
        deaths: data.Global.TotalDeaths,
      },
    ];
    countryData = [
      ...countryData,
      ...data.Countries.map(({ Country, Slug, CountryCode, TotalDeaths }) => ({
        key: CountryCode.toLowerCase(),
        text: mapCountryName(Country),
        value: Slug,
        deaths: TotalDeaths,
      })),
    ];

    countryData = countryData.filter((c) => c.deaths > 1000);
    countryData = countryData.filter((c) => goodCityData.includes(c.value));
  }

  return { loading, error, countryData };
}
