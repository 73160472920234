import {useHistory, useRouteMatch} from "react-router-dom";

export function useCountry(defaultCountry) {
    const match = useRouteMatch("/:country");
    const history = useHistory();

    return {
        country: match?.params?.country || defaultCountry,
        setCountry: c => history.push(`/${c || defaultCountry}`)
    }
}