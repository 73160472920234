export function formatCountryForQuery(country) {
  if (!country) {
    return country;
  }
  country = country.replace("-", " ");
  country = country.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return country.replace(" ", "+");
}

export function mapCountryName(countryName) {
  switch (countryName) {
    case "United States of America":
      return "The United States";
    case "United Kingdom":
      return "The United Kingdom";
    case "Russian Federation":
      return "Russia";
    case "Macedonia, Republic of":
      return "Macedonia";
    case "Iran, Islamic Republic of":
      return "Iran";
    default:
      return countryName;
  }
}
