import useFetch from "use-http";
import { useEffect, useState } from "react";
import { formatCountryForQuery } from "./util";

export function useCity(maxPopulation, countryCode, country) {
  if (countryCode === "world") {
    countryCode = "us";
  }
  if (country === "the-world") {
    country = "united-states";
  }

  country = formatCountryForQuery(country);

  const { get } = useFetch(`https://public.opendatasoft.com`);

  const [data, setData] = useState({
    city: null,
    population: null,
  });

  useEffect(() => {
    setData({ city: null, population: null, from: null });
    if (maxPopulation && countryCode && country) {
      (async () => {
        let responseData = await get(
          `/api/records/1.0/search/?dataset=geonames-all-cities-with-a-population-500&q=population%3C${maxPopulation}&rows=1&sort=population&refine.country_code=${countryCode.toUpperCase()}&exclude.population=0`
        );
        if (
          responseData?.records.length &&
          responseData.records[0].fields.population >= 1000
        ) {
          setData({
            city: responseData.records[0].fields.name,
            population: responseData.records[0].fields.population,
          });
        } else {
          setData({ city: null, population: null });
        }
      })();
    }
  }, [maxPopulation, countryCode, get, setData, country]);

  return data;
}
