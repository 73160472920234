import React from "react";
import { Redirect } from "react-router-dom";
import { Dropdown, Loader } from "semantic-ui-react";
import { useCountry } from "./useCountry";
import { useCovidData } from "./useCovidData";
import { useCity } from "./useCity";
import { useMedia } from "react-media";

export function CovidCity() {
  const isSmallScreen = useMedia({ query: "(max-width: 500px)" });

  const fontSizeSmall = isSmallScreen ? "4.5vw" : "2vw";
  const fontSizeBig = isSmallScreen ? "9.5vw" : "7vw";
  const fontSizeSup = isSmallScreen ? "2.5vw" : "0.8vw";

  const { country, setCountry } = useCountry("the-world");

  const { loading, error, countryData } = useCovidData();
  const selectedCountry = countryData?.find((c) => c.value === country);

  const { city, population } = useCity(
    selectedCountry?.deaths,
    selectedCountry?.key,
    selectedCountry?.value
  );

  if (error)
    return (
      <div>
        Failed to load covid data! Check{" "}
        <a href="https://covid19api.com/">covid19api.com</a>
      </div>
    );
  if (loading) return <Loader active inline="centered" />;

  if (!selectedCountry) {
    return <Redirect to={"/the-world"} />;
  }

  return (
    <div
      style={{ textAlign: "center", paddingTop: "5em", paddingBottom: "0.2em" }}
    >
      <div style={{ fontSize: fontSizeBig }}>
        <Dropdown
          onChange={(e, { value }) => setCountry(value)}
          scrolling
          inline
          options={countryData}
          value={country}
        />
      </div>
      {city ? (
        <>
          <div style={{ fontSize: fontSizeSmall }}>has had</div>
          <div
            style={{
              fontSize: fontSizeBig,
              color: "red",
              paddingBottom: "0.2em",
            }}
          >
            <b>{selectedCountry.deaths.toLocaleString()}</b>
            <sup style={{ verticalAlign: "super" }}>
              <a
                style={{ color: "grey", fontSize: fontSizeSup }}
                target="_blank"
                rel="noreferrer"
                href="https://covid19api.com/"
              >
                [1]
              </a>
            </sup>
          </div>
          <div style={{ fontSize: fontSizeSmall }}>COVID-19 deaths</div>
          <div style={{ fontSize: fontSizeSmall, paddingTop: "0.5em" }}>
            More than the population of
          </div>
          <div
            style={{
              fontSize: fontSizeBig,
              paddingTop: "0.1em",
              paddingBottom: "0.1em",
            }}
          >
            <b>{city}</b>
          </div>
          <div style={{ fontSize: fontSizeSmall, color: "grey" }}>
            ({population.toLocaleString()})
            <sup style={{ verticalAlign: "super" }}>
              <a
                style={{ color: "grey", fontSize: fontSizeSup }}
                target="_blank"
                rel="noreferrer"
                href={
                  "https://public.opendatasoft.com/explore/dataset/geonames-all-cities-with-a-population-500/information/?disjunctive.country"
                }
              >
                [2]
              </a>
            </sup>
          </div>
        </>
      ) : (
        <Loader active inline="centered" />
      )}
    </div>
  );
}
